import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login.component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  locked:any = "/assets/img/eye-close.png";
  username = '';
  clave:string = '';
  auxClave = '';
  estadoClave = false;
  constructor(private router: Router, private loginService: LoginService) { }

  ngOnInit() {
  }

  enmascarar(){
    if(!this.estadoClave){
      
      let longitud = this.clave.length;
      let longitudAux = this.auxClave.length;
     if(longitud >longitudAux){
       this.auxClave=this.auxClave + this.clave.slice( longitudAux,longitud);
       this.clave = '';
       var i;
       for(i=0;i<longitud;i++){
         this.clave = this.clave + '*';
       }
      } else if(longitud <longitudAux){
            this.auxClave = this.auxClave.slice(0,longitud);
      }
     
    } else {
      let longitud = this.clave.length;
      this.auxClave = '';
      var i;
      for(i=0;i<longitud;i++){
          this.auxClave = this.auxClave + '*';
      }
     
    }
  }

  dinamicaClave() {
    if(this.estadoClave){
     this.estadoClave = false;
     var temp = this.clave;
     this.clave =this.auxClave;
     this.auxClave = temp;
     this.locked = "/assets/img/eye-close.png";
    } else{
      this.estadoClave = true;
      var temp = this.clave;
      this.clave =this.auxClave;
      this.auxClave = temp;
      this.locked = "/assets/img/eye.png";
    }
   }

   inicioSesion(){
    let error;
    let response;
    let temp = '';
    let password;
    var i;
    for(i=0;i<this.clave.length;i++){
       temp = temp + '*';
    }
    if(temp == this.clave){
      password = this.auxClave;
    } else {
      password = this.clave;
    }
    this.loginService.login(this.username,password).subscribe(
      res =>{
        response = res;
        this.username = '';
        this.auxClave = '';
        this.clave = '';
        localStorage.setItem('token', response.token);
        localStorage.setItem('type',response.type);
        localStorage.setItem('username',response.username);
        localStorage.setItem('email',response.email);
        localStorage.setItem('rol', response.rol);
        localStorage.setItem('company',response.company);
        localStorage.setItem('storeCode',response.storeCode);
        localStorage.setItem("blocked","false");
        Swal.fire({
          allowOutsideClick: false,
          icon: 'info',
          text: 'Cargando...'
        });
        Swal.showLoading();
        setTimeout(function(){
          console.log("Ingresando al sistema");
        }, 4000);
        Swal.close();
        this.router.navigate(['/home']);
      },
      err =>{
        error=err;
         console.log(error);
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: `Credenciales incorrectas`,
          showConfirmButton: false,
          timer: 1500
        });

      });
    }
}
