import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SpsaService {

  urlInvoiceSpsa = environment.urlApiInvoice;

  constructor(private http: HttpClient) { }
  
  getInvoces(documentNumber, from, to, codigoLocal, nombreLocal, category, nameProduct, codeProduct){
    let de = from.year +"-"+((from.month<10)?"0"+from.month:from.month)+"-"+((from.day<10)?"0"+from.day:from.day);
    let a = to.year+"-"+((to.month<10)?"0"+to.month:to.month)+"-"+((to.day<10)?"0"+to.day:to.day) ;
    codigoLocal = (codigoLocal != undefined) ? "&local="+codigoLocal : "&local=";
    nombreLocal = (nombreLocal != undefined) ? "&local="+nombreLocal : "&local=";
    category = (category != undefined) ? "&linea="+category : "&linea=";
    nameProduct = (nameProduct != undefined) ? "&nombreProducto="+nameProduct : "&nombreProducto=";
    codeProduct = (codeProduct != undefined) ? "&codigoProducto="+codeProduct : "&codigoProducto=";    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.get(`${this.urlInvoiceSpsa}dateFrom=${de}&dateTo=${a}&documentNumber=${documentNumber}${codigoLocal}${category}${nameProduct}${codeProduct}&attachVoucher=true`, httpOptions);
  }

}
