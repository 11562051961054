import { Component, OnInit, ViewChild ,Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDateStruct, NgbCalendar, NgbDatepicker,NgbDateParserFormatter,NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { Invoice } from '../../model/Models';
import Swal from 'sweetalert2';
import { SpsaService } from '../../services/spsa.service';

const I18N_VALUES = {
  'es': {
    weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá', 'Do'],
    months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Aug', 'Set', 'Oct', 'Nov', 'Dic'],
  }
  // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
  language = 'es';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? ((date.day <10) ? "0"+date.day: date.day)+ this.DELIMITER + ((date.month<10) ? "0"+date.month : date.month) + this.DELIMITER + date.year : '';
  }
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [
    I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ]
})
export class HomeComponent implements OnInit, OnDestroy {
  orderInvoices : Invoice [];
  category: string;
  store: string;
  name_product: string;
  code_product: string;
  documentNumber: string;
  dpFromModel: NgbDateStruct;
  dpToModel: NgbDateStruct;
  date: { year: number, month: number };
  @ViewChild('dpFrom',{static:true}) dpFrom: NgbDatepicker;
  @ViewChild('dpTo',{static:true}) dpTo: NgbDatepicker;
  ngOnInit() {
    let flag = localStorage.getItem("blocked");
    if(flag!="false"){
      this.router.navigate(['/']);
    }
  }

  constructor(private calendar: NgbCalendar,private router: Router, private spsaService :  SpsaService) {
  }


  find(){
    let response;
    let error;
     if(this.dpToModel!=undefined && this.dpFromModel!=undefined && this.documentNumber!=undefined) {
      Swal.fire({
        allowOutsideClick: false,
        icon: 'info',
        text: 'Cargando...'
      });
      Swal.showLoading();
      this.spsaService.getInvoces(this.documentNumber, this.dpFromModel, this.dpToModel, this.store, this.store, this.category,this.name_product, this.code_product).subscribe(
        res =>{
          response = res;
          this.orderInvoices = response;
          Swal.close();
        }, err=>{
          error = err;
          console.log(error);
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: `Error en la búsqueda`,
            showConfirmButton: false,
            timer: 1500
          });
        }
      );
      
     } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: `Faltan completar campos obligatorios (*)`,
        showConfirmButton: false,
        timer: 1500
      });
     }
  }

  clean(){
    this.category= undefined;
    this.store= undefined;
    this.name_product = undefined;
    this.code_product = undefined;
    this.documentNumber = undefined;
    this.dpFromModel = undefined;
    this.dpToModel = undefined;
  }

  ngOnDestroy(){
    localStorage.setItem("blocked","true");
  }
}
